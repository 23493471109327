import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import siteStore from '@/store/modules/siteStore.js';
import areaStore from '@/store/modules/areaStore.js';
import indicatorStore from '@/store/modules/indicatorStore.js';
import userStore from '@/store/modules/userStore.js';

const store = createStore({
  modules: {
    siteStore: siteStore,
    areaStore: areaStore,
    indicatorStore : indicatorStore,
    userStore: userStore
  },
  plugins: [createPersistedState({paths: [ "userStore" ]})]
})

export default store
