import { Buffer } from 'buffer';

const userStore = {
  namespaced: true,
  state: {
    token: undefined,
    parsedToken: undefined
  },
  mutations: {
    login: function (state, payload) {
      state.token = payload;
      if (payload != undefined) {
        let parsed = JSON.parse(Buffer.from(payload.split('.')[1], 'base64').toString());
        state.parsedToken = parsed;
      } else {
        state.parsedToken = undefined;
      }
    }
  },
  getters: {
    checkLogin: function (state) {
      const now = (new Date()).getTime() / 1000;

      return state.parsedToken != undefined && state.parsedToken.exp != undefined && state.parsedToken.exp >= now;
    },
    getRoles: function (state) {
      if (state.parsedToken != undefined && state.parsedToken.roles != undefined) {
        return state.parsedToken.roles.split(",").filter(s => s.trim().length !== 0).map(s => "ROLE_" + s.toUpperCase());
      } else {
        return [];
      }
    }
  }
}

export default userStore;
