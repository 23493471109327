const areaStore = {
  namespaced: true,
  state: {
    area: [
      {code:"1000000000", name:"전국",
        child:[
          {code:"0", name:"전체"}
        ]
      },
      {code:"1100000000", name:"서울특별시",
        child:[
          {code:"0", name:"전체"},
          {code:"1111000000", name:"종로구"},
          {code:"1114000000", name:"중구"},
          {code:"1117000000", name:"용산구"},
          {code:"1120000000", name:"성동구"},
          {code:"1121500000", name:"광진구"},
          {code:"1123000000", name:"동대문구"},
          {code:"1126000000", name:"중랑구"},
          {code:"1129000000", name:"성북구"},
          {code:"1130500000", name:"강북구"},
          {code:"1132000000", name:"도봉구"},
          {code:"1135000000", name:"노원구"},
          {code:"1138000000", name:"은평구"},
          {code:"1141000000", name:"서대문구"},
          {code:"1144000000", name:"마포구"},
          {code:"1147000000", name:"양천구"},
          {code:"1150000000", name:"강서구"},
          {code:"1153000000", name:"구로구"},
          {code:"1154500000", name:"금천구"},
          {code:"1156000000", name:"영등포구"},
          {code:"1159000000", name:"동작구"},
          {code:"1162000000", name:"관악구"},
          {code:"1165000000", name:"서초구"},
          {code:"1168000000", name:"강남구"},
          {code:"1171000000", name:"송파구"},
          {code:"1174000000", name:"강동구"},
        ]
      },
      {code:"2600000000", name:"부산광역시",
        child:[
          {code:"0", name:"전체"},
          {code:"2611000000", name:"중구"},
          {code:"2614000000", name:"서구"},
          {code:"2617000000", name:"동구"},
          {code:"2620000000", name:"영도구"},
          {code:"2623000000", name:"부산진구"},
          {code:"2626000000", name:"동래구"},
          {code:"2629000000", name:"남구"},
          {code:"2632000000", name:"북구"},
          {code:"2635000000", name:"해운대구"},
          {code:"2638000000", name:"사하구"},
          {code:"2641000000", name:"금정구"},
          {code:"2644000000", name:"강서구"},
          {code:"2647000000", name:"연제구"},
          {code:"2650000000", name:"수영구"},
          {code:"2653000000", name:"사상구"},
          {code:"2671000000", name:"기장군"},
        ]
      },
      {code:"2700000000", name:"대구광역시",
        child:[
          {code:"0", name:"전체"},
          {code:"2711000000", name:"중구"},
          {code:"2714000000", name:"동구"},
          {code:"2717000000", name:"서구"},
          {code:"2720000000", name:"남구"},
          {code:"2723000000", name:"북구"},
          {code:"2726000000", name:"수성구"},
          {code:"2729000000", name:"달서구"},
          {code:"2771000000", name:"달성군"},
        ]
      },
      {code:"2800000000", name:"인천광역시",
        child:[
          {code:"0", name:"전체"},
          {code:"2811000000", name:"중구"},
          {code:"2814000000", name:"동구"},
          {code:"2817700000", name:"미추홀구"},
          {code:"2818500000", name:"연수구"},
          {code:"2820000000", name:"남동구"},
          {code:"2823700000", name:"부평구"},
          {code:"2824500000", name:"계양구"},
          {code:"2826000000", name:"서구"},
          {code:"2871000000", name:"강화군"},
          {code:"2872000000", name:"옹진군"},
        ]
      },
      {code:"2900000000", name:"광주광역시",
        child:[
          {code:"0", name:"전체"},
          {code:"2911000000", name:"동구"},
          {code:"2914000000", name:"서구"},
          {code:"2915500000", name:"남구"},
          {code:"2917000000", name:"북구"},
          {code:"2920000000", name:"광산구"},
        ]
      },
      {code:"3000000000", name:"대전광역시",
        child:[
          {code:"0", name:"전체"},
          {code:"3011000000", name:"동구"},
          {code:"3014000000", name:"중구"},
          {code:"3017000000", name:"서구"},
          {code:"3020000000", name:"유성구"},
          {code:"3023000000", name:"대덕구"},
        ]
      },
      {code:"3100000000", name:"울산광역시",
        child:[
          {code:"0", name:"전체"},
          {code:"3111000000", name:"중구"},
          {code:"3114000000", name:"남구"},
          {code:"3117000000", name:"동구"},
          {code:"3120000000", name:"북구"},
          {code:"3171000000", name:"울주군"},
        ]
      },
      {code:"3611000000", name:"세종특별자치시",
        child:[
          {code:"0", name:"전체"},
        ]
      },
      {code:"4100000000", name:"경기도",
        child:[
          {code:"0", name:"전체"},
          {code:"4111000000", name:"수원시"},
          {code:"4113000000", name:"성남시"},
          {code:"4115000000", name:"의정부시"},
          {code:"4117000000", name:"안양시"},
          {code:"4119000000", name:"부천시"},
          {code:"4121000000", name:"광명시"},
          {code:"4122000000", name:"평택시"},
          {code:"4125000000", name:"동두천시"},
          {code:"4127000000", name:"안산시"},
          {code:"4128000000", name:"고양시"},
          {code:"4129000000", name:"과천시"},
          {code:"4131000000", name:"구리시"},
          {code:"4136000000", name:"남양주시"},
          {code:"4137000000", name:"오산시"},
          {code:"4139000000", name:"시흥시"},
          {code:"4141000000", name:"군포시"},
          {code:"4143000000", name:"의왕시"},
          {code:"4145000000", name:"하남시"},
          {code:"4146000000", name:"용인시"},
          {code:"4148000000", name:"파주시"},
          {code:"4150000000", name:"이천시"},
          {code:"4155000000", name:"안성시"},
          {code:"4157000000", name:"김포시"},
          {code:"4159000000", name:"화성시"},
          {code:"4161000000", name:"광주시"},
          {code:"4163000000", name:"양주시"},
          {code:"4165000000", name:"포천시"},
          {code:"4167000000", name:"여주시"},
          {code:"4180000000", name:"연천군"},
          {code:"4182000000", name:"가평군"},
          {code:"4183000000", name:"양평군"},
        ]
      },
      {code:"4200000000", name:"강원도",
        child:[
          {code:"0", name:"전체"},
          {code:"4211000000", name:"춘천시"},
          {code:"4213000000", name:"원주시"},
          {code:"4215000000", name:"강릉시"},
          {code:"4217000000", name:"동해시"},
          {code:"4219000000", name:"태백시"},
          {code:"4221000000", name:"속초시"},
          {code:"4223000000", name:"삼척시"},
          {code:"4272000000", name:"홍천군"},
          {code:"4273000000", name:"횡성군"},
          {code:"4275000000", name:"영월군"},
          {code:"4276000000", name:"평창군"},
          {code:"4277000000", name:"정선군"},
          {code:"4278000000", name:"철원군"},
          {code:"4279000000", name:"화천군"},
          {code:"4280000000", name:"양구군"},
          {code:"4281000000", name:"인제군"},
          {code:"4282000000", name:"고성군"},
          {code:"4283000000", name:"양양군"},
        ]
      },
      {code:"4300000000", name:"충청북도",
        child:[
          {code:"0", name:"전체"},
          {code:"4311000000", name:"청주시"},
          {code:"4313000000", name:"충주시"},
          {code:"4315000000", name:"제천시"},
          {code:"4372000000", name:"보은군"},
          {code:"4373000000", name:"옥천군"},
          {code:"4374000000", name:"영동군"},
          {code:"4374500000", name:"증평군"},
          {code:"4375000000", name:"진천군"},
          {code:"4376000000", name:"괴산군"},
          {code:"4377000000", name:"음성군"},
          {code:"4380000000", name:"단양군"},
        ]
      },
      {code:"4400000000", name:"충청남도",
        child:[
          {code:"0", name:"전체"},
          {code:"4413000000", name:"천안시"},
          {code:"4415000000", name:"공주시"},
          {code:"4418000000", name:"보령시"},
          {code:"4420000000", name:"아산시"},
          {code:"4421000000", name:"서산시"},
          {code:"4423000000", name:"논산시"},
          {code:"4425000000", name:"계룡시"},
          {code:"4427000000", name:"당진시"},
          {code:"4471000000", name:"금산군"},
          {code:"4476000000", name:"부여군"},
          {code:"4477000000", name:"서천군"},
          {code:"4479000000", name:"청양군"},
          {code:"4480000000", name:"홍성군"},
          {code:"4481000000", name:"예산군"},
          {code:"4482500000", name:"태안군"},
        ]
      },
      {code:"4500000000", name:"전라북도",
        child:[
          {code:"0", name:"전체"},
          {code:"4511000000", name:"전주시"},
          {code:"4513000000", name:"군산시"},
          {code:"4514000000", name:"익산시"},
          {code:"4518000000", name:"정읍시"},
          {code:"4519000000", name:"남원시"},
          {code:"4521000000", name:"김제시"},
          {code:"4571000000", name:"완주군"},
          {code:"4572000000", name:"진안군"},
          {code:"4573000000", name:"무주군"},
          {code:"4574000000", name:"장수군"},
          {code:"4575000000", name:"임실군"},
          {code:"4577000000", name:"순창군"},
          {code:"4579000000", name:"고창군"},
          {code:"4580000000", name:"부안군"},
        ]
      },
      {code:"4600000000", name:"전라남도",
        child:[
          {code:"0", name:"전체"},
          {code:"4611000000", name:"목포시"},
          {code:"4613000000", name:"여수시"},
          {code:"4615000000", name:"순천시"},
          {code:"4617000000", name:"나주시"},
          {code:"4623000000", name:"광양시"},
          {code:"4671000000", name:"담양군"},
          {code:"4672000000", name:"곡성군"},
          {code:"4673000000", name:"구례군"},
          {code:"4677000000", name:"고흥군"},
          {code:"4678000000", name:"보성군"},
          {code:"4679000000", name:"화순군"},
          {code:"4680000000", name:"장흥군"},
          {code:"4681000000", name:"강진군"},
          {code:"4682000000", name:"해남군"},
          {code:"4683000000", name:"영암군"},
          {code:"4684000000", name:"무안군"},
          {code:"4686000000", name:"함평군"},
          {code:"4687000000", name:"영광군"},
          {code:"4688000000", name:"장성군"},
          {code:"4689000000", name:"완도군"},
          {code:"4690000000", name:"진도군"},
          {code:"4691000000", name:"신안군"},
        ]
      },
      {code:"4700000000", name:"경상북도",
        child:[
          {code:"0", name:"전체"},
          {code:"4711000000", name:"포항시"},
          {code:"4713000000", name:"경주시"},
          {code:"4715000000", name:"김천시"},
          {code:"4717000000", name:"안동시"},
          {code:"4719000000", name:"구미시"},
          {code:"4721000000", name:"영주시"},
          {code:"4723000000", name:"영천시"},
          {code:"4725000000", name:"상주시"},
          {code:"4728000000", name:"문경시"},
          {code:"4729000000", name:"경산시"},
          {code:"4772000000", name:"군위군"},
          {code:"4773000000", name:"의성군"},
          {code:"4775000000", name:"청송군"},
          {code:"4776000000", name:"영양군"},
          {code:"4777000000", name:"영덕군"},
          {code:"4782000000", name:"청도군"},
          {code:"4783000000", name:"고령군"},
          {code:"4784000000", name:"성주군"},
          {code:"4785000000", name:"칠곡군"},
          {code:"4790000000", name:"예천군"},
          {code:"4792000000", name:"봉화군"},
          {code:"4793000000", name:"울진군"},
          {code:"4794000000", name:"울릉군"},
        ]
      },
      {code:"4800000000", name:"경상남도",
        child:[
          {code:"0", name:"전체"},
          {code:"4812000000", name:"창원시"},
          {code:"4817000000", name:"진주시"},
          {code:"4822000000", name:"통영시"},
          {code:"4824000000", name:"사천시"},
          {code:"4825000000", name:"김해시"},
          {code:"4827000000", name:"밀양시"},
          {code:"4831000000", name:"거제시"},
          {code:"4833000000", name:"양산시"},
          {code:"4872000000", name:"의령군"},
          {code:"4873000000", name:"함안군"},
          {code:"4874000000", name:"창녕군"},
          {code:"4882000000", name:"고성군"},
          {code:"4884000000", name:"남해군"},
          {code:"4885000000", name:"하동군"},
          {code:"4886000000", name:"산청군"},
          {code:"4887000000", name:"함양군"},
          {code:"4888000000", name:"거창군"},
          {code:"4889000000", name:"합천군"},
        ]
      },
      {code:"5000000000", name:"제주특별자치도",
        child:[
          {code:"0", name:"전체"},
          {code:"5011000000", name:"제주시"},
          {code:"5013000000", name:"서귀포시"},
        ]
      },
    ],
    areaSet : {}
  },
  getters: {
    GE_AREA_SET : state => state.areaSet
  },
  mutations: {
    MU_AREA_SET : (state, payload) => {
      state.areaSet = payload
    }
  },
  actions: {

  }
}

export default areaStore
