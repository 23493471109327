export default{
  install(Vue){
    //날짜 비교 함수
    Vue.config.globalProperties.$dateValidation = function(prevDate, currentDate){
      if(prevDate > currentDate) {
        //console.log(prevDate,currentDate);
        return false;
      }
      else{
        return true;
      }
    }
    Vue.config.globalProperties.$fnOpenWin=function (url){
      window.open(url, "_blank");
    }
    Vue.config.globalProperties.$fnMovePage=function(url,type=""){
      if(type==="_blank") {
        window.open(url, "_blank")
      } else {
        // const path = url
        // this.$router.push(url)
        if (this.$route.path !== url) this.$router.push(url)
      }
    }

    Vue.config.globalProperties.$fnUseHtml=function(str) {
      return String(str).replace(/(?:\r\n|\r|\n)/g,"<br>");
    }

    Vue.config.globalProperties.$fnAutoLink=function(content) {
      var regURL = new RegExp('(^|[^"])(http|https|ftp|telnet|news|irc)://([-/.a-zA-Z0-9_~#%$?&=:200-377()]+)', 'gi')
      var regURL2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
      return content.replace(regURL, '$1<a class="autoLink" href="$2://$3" target="_blank">$2://$3</a>')
        .replace(regURL2, '$1<a class="autoLink" href="http://$2" target="_blank">$2</a>')
    }

    // pageSet
    Vue.config.globalProperties.$fnPageSet=function(pagesetParam){
      const mn = this.$store.state.siteStore.menus
      //   const po = [s1,s2]
      if(this.$store.state.siteStore.menus.length > 0){
        this.$store.commit('siteStore/MU_PAGESET',{
          pageSet:pagesetParam
        })
        this.$fnLoadingBar(false)
      } else {
        //this.$fnLoadingBar(true)
        setTimeout(() => {
          this.$fnPageSet(pagesetParam)
        }, 100)
      }
    }

    Vue.config.globalProperties.$fnTop=function(){
      window.scrollTo({top:0, left:0, behavior:'auto'});
    }

    // loading bar
    Vue.config.globalProperties.$fnLoadingBar = function(type){
      let obj = this.$refs.globalLoadingBar
      if(type==true){
        obj.loadingView=true
      } else {
        setTimeout(() => {
          obj.loadingView=false
        }, 500)
      }
    }

    Vue.config.globalProperties.$fnComma=function(str){
      const parts = str.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
      //   let obj = str
      //   let tmp = obj.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      //   return tmp
    }

    // 팝업
    Vue.config.globalProperties.$fnPop = function(title, message, type, returnFunc){
      this.$refs.globalPopup.title = title;
      this.$refs.globalPopup.message = message;
      this.$refs.globalPopup.returnFunc = returnFunc;
      // type: ok (기본값), yesno
      this.$refs.globalPopup.dialogType = type;
      this.$refs.globalPopup.dialog = true;
      this.$fnLoadingBar(false);
    }
    // Error 팝업
    Vue.config.globalProperties.$fnErrorCatch = function(err,msg){
      let title = ''
      let message = ''
      title = (!err.response) ? err.code +' ('+ err.status +')' : err.response.status +' ('+ err.response.statusText +')'
      message = (msg) ? msg : err.message
      this.$fnLoadingBar(false)
      this.$fnPop(title,message,'alert','')
    }

    Vue.config.globalProperties.$fnAreaChange=function(){
      let a1 = this.area.sidoCode
      let x = this.$store.state.areaStore.area.find(element => element.code === a1)
      this.gugunItems = x.child
    }

    Vue.config.globalProperties.$fnAreaChangeSet=function(){
      let a1 = this.area.sidoCode
      let a1x = this.$store.state.areaStore.area.find(element => element.code === a1)
      let a2 = this.area.gugunCode
      let a2x = a1x.child.find(element => element.code === a2)
      this.area.sidoName = a1x.name
      this.area.gugunName = a2x.name
      this.$store.commit('areaStore/MU_AREA_SET',{
        area1Code : a1,
        area1Name : a1x.name,
        area2Code : a2,
        area2Name : a2x.name,
      })
    }

    Vue.config.globalProperties.$fnAjax=function(params,actionUrl){
      const apiCall = async() =>{
        try{
          const response = await this.$axios.post(actionUrl,params)
          return response
        } catch(error){
          this.$fnErrorCatch('FALSE', error)
        }
      }
      apiCall()
    }

    Vue.config.globalProperties.$fnColInfo=function(param,data){

      for(let i=1; i<=70; i++){
        if(data[`col`+i] !=""){
          this.headers.push(
            {text: 'data'+i,  value: 'col'+i, align: 'center', sortable: false,}
          )
        }
      }
    }

    // 지표 구간년도에서 마지막년도 추출
    Vue.config.globalProperties.$fnYearTerm=function(yearterm){
      let termY=[]
      let startYear, endYear
      let startYearChar, endYearChar
      let yearItem=[]
      let yearItemRev=[]
      let termYearUse
      let temp
      if(yearterm.indexOf(",") > -1){
        termY = yearterm.split(",")

        startYear = Number(termY[0])
        endYear = Number(termY[termY.length -1])
        startYearChar = startYear+"년"
        endYearChar = endYear+"년"
        yearItemRev=termY
        yearItem=termY.reverse()
        termYearUse = false
        // for(let i=(termY.length -1); i>=0; i--){
        //   yearItem.push(termY[i]);
        // }
      } else {
        termY = yearterm.split("~")
        termYearUse=true
        // 월 사이클일 경우
        if(termY[0].split(".").length > 1){
          let s = termY[0].split(".")
          let e = termY[1].split(".")
          startYear = Number(s[0]+s[1])
          endYear = Number(e[0]+e[1])
          startYearChar = s[0]+"년"+s[1]+"월"
          endYearChar = e[0]+"년"+e[1]+"월"

          let tmpS = Number(s[0]+s[1])
          let tmpE = Number(e[0]+e[1])

          for(let b=Number(e[0]); b>=Number(s[0]); b--){
            for(let k=12; k>=1; k--){
              let m = k
              if(m < 10){
                m = '0'+m
              }
              let c = Number(b+''+m)
              if(tmpE >= c && tmpS <= c){
                yearItem.push(c);
              }
            }
          }
        } else {
          startYear = Number(termY[0])
          endYear = Number(termY[1])
          startYearChar = startYear+"년"
          endYearChar = endYear+"년"
          for(let i=startYear; i<=endYear; i++){
            yearItemRev.push(i);
          }
          for(let b=endYear; b>=startYear; b--){
            yearItem.push(b);
          }
        }
      }
      temp = {
        'termYearUse':termYearUse,
        'startYear':startYear,
        'endYear':endYear,
        'startYearChar':startYearChar,
        'endYearChar':endYearChar,
        'yearItemRev':yearItemRev,
        'yearItem':yearItem,
        'yearterm':yearterm
      }
      return temp
    }

    // GA4 Click function
    Vue.config.globalProperties.$fnGtag=function(event,category,label, value=''){
      if(value){
        this.$gtag.event(event, {
          event_category: category,
          event_label: label,
          value: value,
        })
      } else {
        this.$gtag.event(event, {
          event_category: category,
          event_label: label,
        })
      }
    }

  }
}
