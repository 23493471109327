<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
    transition="dialog-top-transition"
  >
    <v-card>
      <v-card-title class="text-h5">
        <v-spacer />{{ title }}<v-spacer />
      </v-card-title>
      <v-card-text v-if="message != undefined && message != ''">
        <div class="text-center">
          {{ message }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <template v-if="dialogType != undefined && dialogType.toLowerCase() == 'yesno'">
          <v-btn
            color="blue darken-1"
            text
            @click="fnDialog('yes')"
          >
            YES
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="fnDialog('no')"
          >
            NO
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            color="blue darken-1"
            text
            @click="fnDialog('ok')"
          >
            OK
          </v-btn>
        </template>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  name: 'RefDialogAlerts',
  data() {
    return {
      dialog : false,
      dialogType : '',
      title : '',
      message : '',
      returnFunc : ''
    }
  },
  methods: {
    fnDialog(result) {
      this.dialog = false;
      if (this.returnFunc != undefined) {
        this.returnFunc(result);
      }
    }
  }
}
</script>
