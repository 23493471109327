const siteStore = {
  namespaced: true,
  state: {
    siteName: 'CITY PULSE',
    copyright: 'CITY PULSE',

    menus: [
      {
        id: 1000,
        mnUrl: '/page1/areaChoice',
        mnName: '통계분석',
        children: [
          {
            id: 1100,
            mnUrl: '/page/page1_1',
            mnName: '인구/가구',
            img: '/images/indicatorItem_01.png',
          },
          {
            id: 1200,
            mnUrl: '/page/page1_2',
            mnName: '산업/경제',
            img: '/images/indicatorItem_02.png',
          },
          {
            id: 1300,
            mnUrl: '/page/page1_3',
            mnName: '기후/환경',
            img: '/images/indicatorItem_03.png',
          },
          {
            id: 1400,
            mnUrl: '/page/page1_4',
            mnName: '도로/교통',
            img: '/images/indicatorItem_04.png',
          },
          {
            id: 1500,
            mnUrl: '/page/page1_5',
            mnName: '주거/생활',
            img: '/images/indicatorItem_05.png',
          },
          {
            id: 1600,
            mnUrl: '/page/page1_6',
            mnName: '보건/복지',
            img: '/images/indicatorItem_06.png',
          },
          {
            id: 1700,
            mnUrl: '/page/page1_7',
            mnName: '교육/문화',
            img: '/images/indicatorItem_07.png',
          },
          {
            id: 1800,
            mnUrl: '/page/page1_8',
            mnName: '안전/재난',
            img: '/images/indicatorItem_08.png',
          },
          {
            id: 1900,
            mnUrl: '/page/page1_9',
            mnName: '국토/행정',
            img: '/images/indicatorItem_09.png',
          },
        ],
      },
      {
        id: 700,
        mnUrl: '/page/Page7_1',
        mnName: '공간분석',
      },
      {
        id: 701,
        mnUrl: '/page/Page7_2',
        mnName: '여론분석',
      },
      {
        id: 200,
        mnName: '테마분석',
        mnUrl: '/page2/areaChoice',
        children: [
          {
            id: 210,
            mnUrl: '/page/Page2_1',
            mnName: '축소도시',
            img: '/images/themeItem_01.png',
          },
          {
            id: 210,
            mnUrl: '/page/Page2_2',
            mnName: '탄소중립',
            img: '/images/themeItem_02.png',
          },
        ],
      },
      {
        id: 300,
        mnUrl: '/page/Page3_1',
        mnName: '시각화분석',
      },
      {
        id: 500,
        mnUrl: '/page/Page5_1',
        mnName: '리포트',
      },
      {
        id: 600,
        mnUrl: '/serviceGuide',
        mnName: '서비스안내',
      },
    ],
    dotmenus: [],

    familySite: [
      { title: 'Sometrend', to: 'https://some.co.kr/' },
      { title: 'Sometrend Biz', to: 'https://biz.some.co.kr/' },
      { title: '생활변화관측소', to: 'http://www.7outof1000.com/' },
      { title: 'Duplanet', to: 'https://www.duplanet.kr/' },
      { title: 'Finter Labs', to: 'https://labs.finter.kr/' },
      { title: '퀀팃', to: 'https://www.quantit.io/' },
    ],

    pageSet: {
      title: '',
      subtitle: '',
      position: [],
    },
    breadcrumb: [],
  },
  getters: {
    GE_MENU: (state) => state.menus,
    GE_DOTMENU: (state) => state.dotmenus,
    GE_PAGESET: (state) => state.pageSet,
    GE_BREADCRUMB: (state) => state.breadcrumb,
  },
  mutations: {
    MU_MENU: (state, payload) => {
      state.menus = payload;
    },
    MU_DOTMENU: (state, payload) => {
      state.dotmenus = payload;
    },
    MU_PAGESET: (state, payload) => {
      state.pageSet = payload.pageSet;

      if (payload.pageSet.title === undefined) {
        state.pageSet.title = state.menus[payload.pageSet.position[0]].mnName;
        if (payload.pageSet.position[1] != undefined) {
          state.pageSet.title =
            state.menus[payload.pageSet.position[0]].children[
              payload.pageSet.position[1]
            ].mnName;
        }
        if (payload.pageSet.position[2] != undefined) {
          state.pageSet.title =
            state.menus[payload.pageSet.position[0]].children[
              payload.pageSet.position[1]
            ].children[payload.pageSet.position[2]].mnName;
        }
      }

      state.breadcrumb = [{ title: 'HOME', disabled: true }];
      if (payload.pageSet.position != undefined && payload.pageSet.position.length > 0) {
        if (payload.pageSet.position[0] != undefined) {
          state.breadcrumb.push({
            title: state.menus[payload.pageSet.position[0]].mnName,
            disabled: true,
          });
        }
        if (payload.pageSet.position[1] != undefined) {
          state.breadcrumb.push({
            title:
              state.menus[payload.pageSet.position[0]].children[
                payload.pageSet.position[1]
              ].mnName,
            disabled: true,
          });
        }
        if (payload.pageSet.position[2] != undefined) {
          state.breadcrumb.push({
            title:
              state.menus[payload.pageSet.position[0]].children[
                payload.pageSet.position[1]
              ].children[payload.pageSet.position[2]].mnName,
            disabled: true,
          });
        }
      } else if (payload.pageSet.breadcrumb != undefined && payload.pageSet.breadcrumb.length > 0) {
        for (const title of payload.pageSet.breadcrumb) {
          state.breadcrumb.push({ title, disabled: true });
        }
      }
    },
    MU_PAGETITLE: (state, payload) => {
      state.pageSet.title = payload;
    },
    MU_PAGESET_DIRECT: (state, payload) => {
      state.pageSet = payload;
    },
    MU_BREADCRUMB: (state, payload) => {
      state.breadcrumb = payload.breadcrumb;
    },
  },
  actions: {
    AC_PAGESET: ({ commit }, payload) => {
      commit('MU_PAGESET', payload);
    },

    AC_MENU: ({ commit }, payload) => {
      commit('MU_MENU', payload);
    },
    AC_DOTMENU: ({ commit }, payload) => {
      commit('MU_DOTMENU', payload);
    },
  },
};

export default siteStore;
