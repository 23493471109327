import { createRouter, createWebHistory } from 'vue-router';
import stores from '../store/store.js';

const routes = [
  // { path : '*', redirect: "/404" },
  { path: '/404', component: () => import('@/views/common/NotFound.vue') },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/common/Login.vue'),
    meta: { title: 'City Pulse Login' }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('@/views/common/SignUp.vue'),
    meta: { title: 'City Pulse Sign Up' }
  },
  {
    path: '/userinfo',
    component: () => import('@/views/common/UserInfo.vue'),
    meta: { title: 'City Pulse User Info' }
  },
  {
    // 충청남도 POC
    path: '/chungnam',
    name: 'ChungnamLayout',
    component: () => import('@/views/usr/Chungnam/Layout.vue'),
    children: [
      {
        path: '/chungnam',
        name: 'Chungnam',
        meta: { title: '여론분석' },
        component: () => import('@/views/usr/Chungnam/Page7_3.vue'),
      },
      {
        path: '/chungnam/list',
        name: 'ChungnamList',
        meta: { title: '충청남도청 여론분석 AI 보고서' },
        component: () => import('@/views/usr/Chungnam/AIReportList.vue'),
      }
    ]
  },
  {
    path: '/',
    name: 'UsrIndex',
    component: () => import('@/views/usr/Layout.vue'),
    meta: { unauthorized: true },
    children: [
      { path: '/', redirect: '/home' },
      {
        path: '/home',
        name: 'Main',
        meta: { title: 'City Pulse Main' },
        component: () => import('@/views/usr/Main.vue'),
      },
      {
        path: '/page1/areaChoice',
        meta: { title: '통계분석' },
        component: () => import('@/views/usr/page1/AreaChoice.vue'),
      },
      {
        path: '/page/page1_1',
        name: 'Page1_1',
        meta: { title: '통계분석 > 인구/가구' },
        component: () => import('@/views/usr/page1/Page1_1.vue'),
      },
      {
        path: '/page/page1_2',
        name: 'Page1_2',
        meta: { title: '통계분석 > 산업/경제' },
        component: () => import('@/views/usr/page1/Page1_2.vue'),
      },
      {
        path: '/page/page1_3',
        name: 'Page1_3',
        meta: { title: '통계분석 > 기후/환경' },
        component: () => import('@/views/usr/page1/Page1_3.vue'),
      },
      {
        path: '/page/page1_4',
        name: 'Page1_4',
        meta: { title: '통계분석 > 도로/교통' },
        component: () => import('@/views/usr/page1/Page1_4.vue'),
      },
      {
        path: '/page/page1_5',
        name: 'Page1_5',
        meta: { title: '통계분석 > 주거/생활' },
        component: () => import('@/views/usr/page1/Page1_5.vue'),
      },
      {
        path: '/page/page1_6',
        name: 'Page1_6',
        meta: { title: '통계분석 > 보건/복지' },
        component: () => import('@/views/usr/page1/Page1_6.vue'),
      },
      {
        path: '/page/page1_7',
        name: 'Page1_7',
        meta: { title: '통계분석 > 교육/문화' },
        component: () => import('@/views/usr/page1/Page1_7.vue'),
      },
      {
        path: '/page/page1_8',
        name: 'Page1_8',
        meta: { title: '통계분석 > 안전/재난' },
        component: () => import('@/views/usr/page1/Page1_8.vue'),
      },
      {
        path: '/page/page1_9',
        name: 'Page1_9',
        meta: { title: '통계분석 > 국토/행정' },
        component: () => import('@/views/usr/page1/Page1_9.vue'),
      },
      {
        path: '/page2/areaChoice',
        meta: { title: '테마분석' },
        component: () => import('@/views/usr/page2/AreaChoice.vue'),
      },
      {
        path: '/page/page2_1',
        name: 'Page2_1',
        meta: { title: '테마분석 > 축소도시' },
        component: () => import('@/views/usr/page2/Page2_1.vue'),
      },
      {
        path: '/page/page2_2',
        name: 'Page2_2',
        meta: { title: '테마분석 > 탄소중립' },
        component: () => import('@/views/usr/page2/Page2_2.vue'),
      },
      {
        path: '/page/page3_1',
        name: 'Page3_1',
        meta: { title: '시각화분석' },
        component: () => import('@/views/usr/page3/Page3_1.vue'),
      },
      {
        path: '/page/page4_1',
        name: 'Page4_1',
        meta: { title: '3D 교차분석' },
        component: () => import('@/views/usr/page4/Page4_1.vue'),
      },
      {
        path: '/page/page5_1',
        name: 'Page5_1',
        meta: { title: '소셜분석' },
        component: () => import('@/views/usr/page5/Page5_1.vue'),
      },
      {
        path: '/serviceGuide',
        name: 'serviceGuide',
        meta: { title: 'City Pulse 안내' },
        component: () => import('@/views/usr/page6/Page6_1.vue'),
      },
      {
        path: '/page/page7_1',
        name: 'Page7_1',
        meta: { title: '공간분석' },
        component: () => import('@/views/usr/page7/Page7_1.vue'),
      },
      {
        path: '/page/page7_2',
        name: 'Page7_2',
        meta: { title: '여론분석' },
        component: () => import('@/views/usr/page7/Page7_2.vue'),
      },
      {
        path: '/emailconfirm/:code',
        name: 'EmailConfirmation',
        meta: { title: '이메일 확인' },
        component: () => import('@/views/usr/EmailConfirmation.vue'),
      },
    ],
  },
  {
    path: '/admin',
    name: 'AdminIndex',
    component: () => import('@/views/admin/AdminLayout.vue'),
    meta: { unauthorized: true },
    children: [
      { path: '/admin', redirect: '/admin/userManagement' },
      {
        path: '/admin/userManagement',
        name: 'UserManagement',
        meta: { title: '사용자 관리' },
        component: () => import('@/views/admin/UserManagement.vue'),
      }
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const allowedToAnonymous = [
  /^\/login$/,
  /^\/signup$/,
  /^\/home$/,
  /^\/emailconfirm\/[0-9a-zA-Z]{8}$/,
  /^\/serviceGuide$/,
  /^\/chungnam\/list$/,
  /^\/$/
];

const chungnam = [
  /^\/chungnam$/
];

router.beforeEach((to, from, next) => {
  const isAllowedToAnonymous = allowedToAnonymous.some(regex => regex.test(to.path));

  if (!isAllowedToAnonymous) {
    const checkLogin = stores.getters['userStore/checkLogin'];

    if (!checkLogin) {
      const isChungnam = chungnam.some(regex => regex.test(to.path));
      if (isChungnam) next({ name: 'Login', state: { 'redirect_to': '/chungnam' }});
      else next({ name: 'Login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  document.title = to.meta.title;
});
export default router;
