const indicatorStore = {
  namespaced: true,
  state: {
    indicator: [],
  },
  getters: {
    GE_IND : state => state.indicator
  },
  mutations: {
    MU_IND : (stage, payload) => {
      stage.indicator = payload
    }
  },
  actions: {
    AC_IND : ({commit}, payload) => {
      commit('MU_IND', payload)
    }
  }
}

export default indicatorStore
