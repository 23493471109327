<template>
  <v-app id="App">
    <router-view />
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
    drawer:false,
    right: null,
  }),
}
</script>
