<template>
  <!-- loading -->
  <v-dialog
    v-model="loadingView"
    persistent
    width="200"
  >
    <v-progress-linear
      indeterminate
      height="10"
      color="white"
    />
  </v-dialog>
</template>

<script>
export default {
  name: 'RefLoadingDialog',
  data () {
    return{
      loadingView : false,
    }
  },
  watch : {
  },

  methods: {

  }
}
</script>
